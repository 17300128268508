import React, { useEffect, useRef, memo } from 'react';

function AdvancedChartTradingView(coinSymbol) {
    const container = useRef();
    const symbol = '"'+coinSymbol.coinSymbol+'"';

    useEffect(
        () => {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
        {
          "autosize": true,
          "symbol": ${symbol},
          "interval": "240",
          "timezone": "Etc/UTC",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "enable_publishing": false,
          "hide_side_toolbar": false,
          "allow_symbol_change": true,
          "support_host": "https://www.tradingview.com"
        }`;
            container.current.appendChild(script);
        },
        []
    );

    return (
        <div className="tradingview-widget-container" ref={container}>
        </div>
    );
}

export default memo(AdvancedChartTradingView);
