import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Home from "./Home"
import Metrics from "./Metrics"
import Blog from "./Blog"
import Events from "./Events"
import BlogArticle from "./BlogArticle"
import Layout from "./components/Layout";
import LayoutEmailForm from "./components/LayoutEmailForm";
import styles from './css/dark-theme.module.css';
import LogoW from "./images/logo_w.png";
import UnsubscribeFromEmailSender from "./UnsubscribeFromEmailSender";
import Cryptocurrencies from "./Cryptocurrencies";
import CryptocurrencyPage from "./CryptocurrencyPage";
import Airdrops from "./Airdrops";
import AirdropPage from "./AirdropPage";
import PlayToEarn from "./PlayToEarn";
import PlayToEarnPage from "./PlayToEarnPage";
import ScamCryptocurrencies from "./ScamCryptocurrencies";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Telegram, Twitter, Shop, YouTube } from '@mui/icons-material';

function App() {
    const today = new Date();
    const year = today.getFullYear();

    return (
    <div className={styles.App}>
        <Layout/>
        <BrowserRouter>
            <Routes>
                <Route >
                    <Route path="/" element={<Home />} />
                    <Route path="metrics" element={<Metrics />} />
                    <Route path="blog" element={<Blog />} />
                    <Route path="/blog/:link" element={<BlogArticle />} />
                    <Route path="events" element={<Events />} />
                    <Route path="unsubscribe" element={<UnsubscribeFromEmailSender />} />
                    <Route path="currencies" element={<Cryptocurrencies />} />
                    <Route path="/currencies/:link" element={<CryptocurrencyPage />} />
                    <Route path="airdrops" element={<Airdrops />} />
                    <Route path="/airdrops/:link" element={<AirdropPage />} />
                    <Route path="playtoearn" element={<PlayToEarn />} />
                    <Route path="/playtoearn/:link" element={<PlayToEarnPage />} />
                    <Route path="/scam_tokens" element={<ScamCryptocurrencies />} />
                </Route>
            </Routes>
        </BrowserRouter>
        <Box xs={{ flexGrow: 1 }}>
            <Grid item xs={12} lg={12} p={5} className={styles.footer_logo}>
                <LayoutEmailForm />
            </Grid>
        </Box>

        <footer className={styles.footer}>
            <Box xs={{ flexGrow: 1 }}>
                <Grid container spacing={2} p={3} direction="row" justifyContent="left" alignItems="center">
                    <Grid item xs={12} lg={5} className={styles.footer_logo}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="left"
                            alignItems="center"
                        >
                        <Grid className={styles.header_web_menu} item xs={2} lg={1}>
                            <a href={'/'}>
                                <img src={LogoW} width={'50'} className="back" alt="logo"/>
                            </a>
                        </Grid>
                        <Grid className={styles.header_web_menu} item xs={10} lg={5}>
                            <a href={'/'}>
                                <p>Coinscope</p>
                            </a>
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} lg={2} className={styles.footer_column_header}>
                        <p>Menu</p>
                    </Grid>
                    <Grid item xs={6} lg={2} className={styles.footer_column_header}>
                        <p>Help</p>
                    </Grid>
                    <Grid item xs={12} lg={3} className={styles.footer_column_header}>
                        <p>Stay connected with us</p>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={styles.footer_secondary_text} direction="row" justifyContent="left" alignItems="top">
                    <Grid item xs={12} lg={5} className={styles.footer_description}>
                        <p>COINSCOPE is a reputable publisher of financial information; however, please note that we do not operate as an investment advisor. We do not offer personalized or customized investment advice. It's important to understand that investing in cryptocurrencies involves substantial risks, including the potential for permanent and total loss. Past performance should not be seen as a guarantee of future results. Any pictures and diagrams provided are accurate at the time of publication or as specified. While we may present live tested strategies, they should not be considered as recommendations.

                            We strongly recommend consulting with a qualified financial advisor before making any financial decisions. They can provide tailored guidance based on your specific circumstances.</p>
                    </Grid>
                    <Grid item xs={6} lg={2} className={styles.footer_menu_links}>
                        <p><a href={'/'}>Home </a></p>
                        <p><a href={'/metrics'}>Metrics </a></p>
                        <p><a href={'/blog'}>Blog </a></p>
                        <p><a href={'/events'}>Events </a></p>
                        <p><a href={'/currencies'}>Cryptocurrencies </a></p>
                        <p><a href={'/airdrops'}>Airdrops </a></p>
                        <p><a href={'/playtoearn'}>Play to Earn </a></p>
                        <p><a href={'/scam_tokens'}>Scam tokens </a></p>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <p><a href={'/privacy-and-policy'}>Privacy and Policy </a></p>
                        <p><a href={'/terms'}>Terms of use </a></p>
                        <p>info@coinscope.app</p>
                    </Grid>
                    <Grid item xs={12} lg={3} className={styles.footer_social_media_links}>
                        <p>keep in touch with us for</p>
                        <p>more updated information</p>
                        <p>
                            <a href={'https://t.me/coinscopeapp'} target={'_blank'} rel="noreferrer"><Telegram /></a>
                            <a href={'https://twitter.com/coinscopeapp'} target={'_blank'} rel="noreferrer"><Twitter /></a>
                            <a href={'https://www.youtube.com/@Coinscopeapp'} target={'_blank'} rel="noreferrer"><YouTube /></a>
                            <a href={'https://play.google.com/store/apps/details?id=com.webshifty.coinscopeapp'} target={'_blank'} rel="noreferrer"><Shop /></a>
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={2} p={5}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {year} © Coinscope
                    </Grid>
                </Grid>
            </Box>
        </footer>
    </div>
  );
}

export default App;
