import Lottie from 'react-lottie-player';
import styles from "./css/dark-theme.module.css";
import React, {Component} from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import parse from 'html-react-parser';
import loadingLottieJson from "./lottie/animation_lkfxk9f1";
import {Helmet} from "react-helmet";
import {Telegram, Twitter, YouTube} from "@mui/icons-material";
import AdvancedChartTradingView from "./components/tokens-charts/AdvancedChartTradingView";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import ReactEcharts from "echarts-for-react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Alert from 'react-bootstrap/Alert';

const authKey = process.env.REACT_APP_AUTH_KEY;
const prodServerUrl = process.env.REACT_APP_PROD_SERVER;
const localServerUrl = process.env.REACT_APP_LOCAL_SERVER;
const nodeEnv = process.env.REACT_APP_NODE_ENV;
let serverUrl = 'Check env';
if (nodeEnv === 'dev') {
    serverUrl = localServerUrl;
}
if (nodeEnv === 'prod') {
    serverUrl = prodServerUrl;
}

class CryptocurrencyPage extends Component {

    state = {
        isLoaded: false,
        apiData: [],
        serverUrl: '',
        pageLink: '',
    };
    async componentDidMount() {
        const pageLink = window.location.pathname.slice(12);
        const packets = {
            authToken: authKey,
        };
        try {
            await axios.post(serverUrl + '/apiv2/tokenid/' + pageLink, packets, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                this.setState({ apiData: response.data.data, isLoaded: true, serverUrl: serverUrl, pageLink: pageLink,});
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {

        const lottieWidth = window.innerWidth * 30 / 100;

        if (!this.state.isLoaded) {
            return (
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                    <Grid item xs={12} lg={4}> </Grid>
                    <Grid item xs={12} lg={4}>
                        <Lottie
                            loop
                            animationData={loadingLottieJson}
                            play
                            style={{width: lottieWidth, align:"center", }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}> </Grid>
                </Grid>
            );
        }
        const item = this.state.apiData['coinData'];
        const latestNews = this.state.apiData['latestNews'];
        const newsSentiment = this.state.apiData['newsSentiment'];
        const longShortRatio = this.state.apiData['longShortRatio'].original;
        let longShortRatioBlock = [];
        let tagScam = [];
        const server = this.state.serverUrl;
        const pageLink = this.state.pageLink;
        let url = server+'/currencies/'+pageLink;
        let hideContracts = '';
        let contracts = '';

        if (item.contracts == null) {
            hideContracts = 'none';
        } else {
            contracts = parse(item.contracts);
        }

        if ((longShortRatio).length === 0) {
            longShortRatioBlock = null;
        } else {
            longShortRatioBlock.push(
                (
                    <Grid item xs={12} lg={12} p={5}>
                        <h3>{item.coin_name} Long/Short Ratio</h3>
                        <ProgressBar>
                            <ProgressBar variant="success" now={longShortRatio[0].longAccount * 100}
                                         label={`${(longShortRatio[0].longAccount * 100).toFixed(2)}%`} key={1}/>
                            <ProgressBar variant="danger" now={longShortRatio[0].shortAccount * 100}
                                         label={`${(longShortRatio[0].shortAccount * 100).toFixed(2)}%`} key={2}/>
                        </ProgressBar>
                    </Grid>
                )
            )
        }

        if (item.tag_scam === 1){
            tagScam.push(
                <Grid item xs={12} lg={12}>
                    <Alert key='danger' variant='danger'>
                        Our team has flagged this token as a scam. We strongly recommend that you do not buy it. Attempting to convert this token may result in the loss of all your funds.
                    </Alert>
                </Grid>
            )
        }
        const option = {
            series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    center: ['50%', '75%'],
                    radius: '90%',
                    min: 0,
                    max: 1,
                    splitNumber: 8,
                    axisLine: {
                        lineStyle: {
                            width: 6,
                            color: [
                                [0.33, '#FF6E76'],
                                [0.66, '#FDDD60'],
                                [1, '#7CFFB2']
                            ]
                        }
                    },
                    pointer: {
                        icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                        length: '12%',
                        width: 20,
                        offsetCenter: [0, '-60%'],
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        length: 12,
                        lineStyle: {
                            color: 'auto',
                            width: 2
                        }
                    },
                    splitLine: {
                        length: 20,
                        lineStyle: {
                            color: 'auto',
                            width: 5
                        }
                    },
                    axisLabel: {
                        color: '#ffffff',
                        fontSize: 16,
                        distance: -40,
                        rotate: 'tangential',
                        formatter: function (value: number) {
                            if (value === 0.875) {
                                return 'Positive';
                            } else if (value === 0.5) {
                                return 'Neutral';
                            } else if (value === 0.125) {
                                return 'Negative';
                            }
                            return '';
                        }
                    },
                    title: {
                        color: '#ffffff',
                        offsetCenter: [0, '-10%'],
                        fontSize: 20
                    },
                    detail: {
                        fontSize: 30,
                        offsetCenter: [0, '-35%'],
                        valueAnimation: true,
                        formatter: function (value: number) {
                            return Math.round(value * 100) + '';
                        },
                        color: 'inherit'
                    },
                    data: [
                        {
                            value: newsSentiment,
                            name: 'News Sentiment'
                        }
                    ]
                }
            ]
        };

        return (
            <div>
                <Helmet>
                    <title>{item.coin_name} || Coinscope App</title>
                    <meta name="description" content={item.coin_about} />
                    <link rel="canonical" href={url} />
                    <meta property="og:title" content={item.coin_name} />
                    <meta property="og:description" content={item.coin_about} />
                    <meta property="og:image" content={item.coin_logo} />
                    <meta property="og:url" content={url} />
                    <meta property="og:type" content="article" />
                    <meta name="twitter:title" content={item.coin_name} />
                    <meta name="twitter:description" content={item.coin_about} />
                    <meta name="twitter:image" content={item.coin_logo} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": "${item.coin_name}",
                        "image": "${item.coin_logo}",
                        "datePublished": "${item.created_at}",
                        "dateModified": "${item.created_at}",
                        "author": [{
                        "@type": "Person",
                        "name": "Coinscope App",
                        "url": "https://coinscope.app"
                    },{
                        "@type": "Person",
                        "name": "Coinscope App",
                        "url": "https://coinscope.app"
                    }]
                    }
                `}</script>
                </Helmet>
                <Box sx={{flexGrow: 1}} p={1}>
                    <Grid container spacing={2} rowSpacing={4} direction="row" justifyContent="center" p={5}>

                        {tagScam}

                        <Grid item xs={12} lg={6} minHeight={450} maxHeight={650}>
                            <AdvancedChartTradingView coinSymbol={item.coin_symbol_tradingview}/>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <h2><img src={item.coin_logo} height={24} width={24} alt={item.coin_symbol} /> {item.coin_name} <small className={styles.coinSymbol}>{item.coin_symbol}</small></h2>
                            <p>Market cap - ${item.market_cap}</p>
                            <p>Total supply - {item.total_supply} <span className={styles.coinSymbol}>{item.coin_symbol}</span></p>
                            <p>Max. supply - {item.max_supply} <span className={styles.coinSymbol}>{item.coin_symbol}</span></p>
                            <p>Website - {item.website_link}</p>
                            <p>All Time High - ${item.all_time_high}</p>
                            <Grid sx={{ display: hideContracts }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Contracts:
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>
                                        {contracts}
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                            </Grid>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    About
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>
                                        {parse(item.coin_about)}
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {longShortRatioBlock}

                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" p={5}>
                            <Grid item xs={12} lg={3}>
                            <ReactEcharts option={option} />
                            </Grid>
                            {latestNews.data.map((post) => {
                                return (

                                    <Grid item xs={12} lg={3}>
                                        <Card sx={{  }}>
                                            <CardActionArea href={'/blog/'+post.link}>
                                                <CardMedia
                                                    component="img"
                                                    height="200"
                                                    image={post.image}
                                                    alt={post.title}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        {post.title}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {post.short_description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Grid item xs={12} lg={12} className={styles.blogArticleText}>
                            <h3>Follow us!</h3>
                            <a href={'https://t.me/coinscopeapp'} target={'_blank'}><Telegram style={{ color: 'white', fontSize: 40 }}/></a>
                            <a href={'https://twitter.com/coinscopeapp'} target={'_blank'}> <Twitter style={{ color: 'white', fontSize: 40 }}/></a>
                            <a href={'https://www.youtube.com/@Coinscopeapp'} target={'_blank'}> <YouTube style={{ color: 'white', fontSize: 40 }}/></a>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}
export default CryptocurrencyPage;